import React, { useState } from 'react';
import { navigate } from 'gatsby';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'gatsby';
import { Formik } from 'formik';
import * as yup from 'yup';

import { loginUser } from '../../services/auth.api';
import styles from './login.module.scss';
import GoogleButton from '../common/GoogleButton';
import FacebookButton from '../common/FacebookButton';

import { StatusType } from '../../types';
import Loader from '../common/Loader';
import LoginFields from './LoginFields';
import { OrderType } from 'models';
import { getCurrentBasket } from 'services/basket.api';
import { useDispatch } from 'react-redux';
import { fetchCurrentBasket } from 'state';

interface Props {
  type: OrderType | string;
}

export const Login = (props: Props) => {
  const { type } = props;
  const dispatch=useDispatch();
  const isOneOffOrder = type === OrderType.OneOff;

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [status, setStatus] = useState({ message: '', type: StatusType.none });
  const schema = yup.object({
    email: yup
      .string()
      .email()
      .required()
      .label('Email'),
    password: yup
      .string()
      .required()
      .label('Password'),
  });

  const navigateTo = isOneOffOrder
    ? `/checkout?ot=${OrderType.OneOff}`
    : '/account/dashboard/';

  const onSubmit = async values => {
    setLoading(true);
    try {
      await loginUser(values);
      navigate(navigateTo);
      fetchCurrentBaskets();//bug fix cart bug
    } catch (error) {
      setLoading(false);
      setStatus({
        message: isOneOffOrder
          ? 'Looks like you\'re new here! Click "continue" at the top of the page to complete your checkout'
          : 'Invalid credentials. Please check and try again.',
        type: StatusType.error,
      });
    }
  };
  const fetchCurrentBaskets=()=>{
    dispatch(fetchCurrentBasket());
  }

  return (
    <div className="mgn-top-50">
      {pageLoading && <Loader />}
      <Container>
        {isOneOffOrder ? (
          <Row className={`mgn-bot-30 mgn-top-30 ${styles.header}`}>
            <Col className="text-center">
              <h2 className="text-center">First time shopping with us?</h2>
              <div className={styles.continueBtn}>
                <Button
                  className="btn btn-def btn-primary"
                  onClick={() => {
                    navigate(`/checkout?ot=${OrderType.OneOff}`);
                  }}
                >
                  Continue
                </Button>
              </div>
              <hr />
              <h2 className="text-center">OR</h2>
              <h2 className="text-center">Already a Feed My Furbaby Member?</h2>
            </Col>
          </Row>
        ) : (
          <Row className={`mgn-bot-30 mgn-top-30 ${styles.header}`}>
            <Col>
              <h2 className="text-center">Login</h2>
            </Col>
          </Row>
        )}
        <div className={styles.loginContainer}>
          {
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Formik
              validationSchema={schema}
              onSubmit={onSubmit}
              initialValues={{
                email: '',
                password: '',
                remember: false,
              }}
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <LoginFields
                    errors={errors}
                    handleChange={handleChange}
                    values={values}
                    touched={touched}
                    isMainPage={true}
                    loading={loading}
                    status={status}
                  />
                  <div className="text-center mgn-bot-20">OR</div>
                  <Row>
                    {/* <Col xs={12} sm={6} className="text-center mgn-bot-30">
                      <FacebookButton
                        text="Login with Facebook"
                        navigateTo={navigateTo}
                        setPageLoading={setPageLoading}
                        onLogin={()=>{fetchCurrentBaskets()}}
                      />
                    </Col> */}
                    <Col xs={12} sm={12} className="text-center mgn-bot-30">
                      <GoogleButton
                        text="Login with Google"
                        navigateTo={navigateTo}
                        setPageLoading={setPageLoading}
                        onLogin={()=>{fetchCurrentBaskets()}}
                      />
                    </Col>
                  </Row>
                  {!isOneOffOrder && (
                    <Row className="text-center mgn-top-20 mgn-bot-20">
                      <Col xs={12}>Not a Feed My Furbaby member yet? </Col>
                      <Col>
                        <Link to="/getstarted/" className="primary">
                          Get started
                        </Link>
                      </Col>
                    </Row>
                  )}
                </Form>
              )}
            </Formik>
          }
        </div>
      </Container>
    </div>
  );
};

export default Login;
